import React from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { TeamCard } from "./TeamCard";
import jamiepfp from "../assets/img/jamie_pfp.gif";
import jovanpfp from "../assets/img/jovan_pfp.gif";
import yajpfp from "../assets/img/yaj_pfp.png";
import callumpfp from "../assets/img/callum_pfp.png";
import dustinpfp from "../assets/img/dusin_pfp.png";
import clearmistpfp from "../assets/img/clearmist_pfp.gif";
import coolexpfp from "../assets/img/coolex_pfp.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Team = () => {

  const team = [
    {
      title: "Jamie",
      description: "Founder",
      imgUrl: jamiepfp,

    },
    {
      title: "Jovan",
      description: "Leadership",
      imgUrl: jovanpfp,
    },
    {
      title: "Yaj",
      description: "Leadership",
      imgUrl: yajpfp,
    },
    {
      title: "Callum",
      description: "Leadership",
      imgUrl: callumpfp,
    },
    {
      title: "Dustin",
      description: "Leadership",
      imgUrl: dustinpfp,
    },
    {
      title: "ClearMist",
      description: "Senior Support",
      imgUrl: clearmistpfp,
    },
    {
      title: "COOLEX",
      description: "Senior Support",
      imgUrl: coolexpfp,
    },
    
  ];

  return (
    <section className="team" id="team">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <br></br>
               
                <br></br>
                <br></br>
                <center><h2>Team</h2>
                <p>This is our little team, we try to keep the cogs turning!</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          team.map((team, index) => {
                            return (
                              <TeamCard
                                key={index}
                                {...team}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container></center>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
