import React from 'react';

import andromeda from "../assets/img/HextroLogoColour.png";
import vintheruler1 from "../assets/img/vintheruler1.png";
import entrain from "../assets/img/entrain.gif";
import notghosty from "../assets/img/notghosty.png";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";
<script async defer src="https://buttons.github.io/buttons.js"></script>

export const Testnet = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="testnet" id="testnet">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2> Reviews✨</h2>
                        <p>Here's some reviews left by our users! </p>
                        <br></br>
                        <br></br>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                        <div className="item">
                            <a target="_blank">  <img src={vintheruler1} alt="Image" /></a>
                            <a target="_blank">  <h5>vintheruler1</h5></a>
                            <a target="_blank">  <h6>amazing host. practically 24/7 support. fair price for a server and generous amounts of ram and cpu and storage. would recrommend</h6></a>
                            </div>

                            <div className="item">
                            <a target="_blank">  <img src={entrain} alt="Image" /></a>
                            <a target="_blank">  <h5>entrain.</h5></a>
                            <a target="_blank">  <h6>Even though the service isn't free, hosting your bot here is the best decision you can make. If you want to make sure your information is secure and your getting what your paying for, this is it. And one more thing, it's worth more than $1 so don't complain.</h6></a>
                            </div>

                            <div className="item">
                            <a target="_blank">  <img src={notghosty} alt="Image" /></a>
                            <a target="_blank">  <h5>not.ghosty</h5></a>
                            <a target="_blank">  <h6>one of the best</h6></a>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
  
}
