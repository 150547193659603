import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/discord.svg";
import navIcon2 from "../assets/img/github.svg";
import navIcon3 from "../assets/img/twitter.svg";
import navIcon4 from '../assets/img/youtube.svg';
import headerImg from "../assets/img/HexLogoText.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
          { <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility> }
            <a href="//www.dmca.com/Protection/Status.aspx?ID=cd54ea61-12b5-47c8-8b42-ff8b144be24c" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca-badge-w250-2x1-03.png?ID=cd54ea61-12b5-47c8-8b42-ff8b144be24c"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
                <a href="https://discord.gg/pWScCJ7EPZ" target="_blank"><img src={navIcon1} alt="Discord" /></a>
                <a href="https://twitter.com/dev_moments" target="_blank"><img src={navIcon3} alt="Twitter" /></a>
            </div>
            <p>Hextro Hosting | 2023</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
